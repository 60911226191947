@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@layer base{
html, body{
  font-family: 'Open Sans', sans-serif;
  @apply antialiased;
  width:100%
}}
.dark backToTopButton{
  text-color:white
  }
.dark {
  background: black;
}
.dark p {
  color: white;
}
.dark h3{
  color: white;
}
.dark a{
color:gray;}
p{
color: white;}
.dark h1 {
  color: white;
}
p{
  color: black;
}
h1{
color: black;}

NavLink{
color: black;}
.container{
  width: 100%;
}
.menu-icons{
  display:none;
}
@media (max-width: 500px){
  .container{
    font-size:12px;
  }
  .NavbarItems{
    display: none;
  }
  .TLogo{
    width: 20%;
  }
  .darkButton{
    height:3rem;margin-left:10rem;margin-top:-3rem;
    display:position-relative;
  }
  .TContact1{
    margin-top:80px;
  }
  .THeader{
    height:4rem;
  }
  .Tservice{
    display:grid;
    row-gap:10px;margin-right:50px;
    justify-items:stretch;margin-left:-15px;
    column-gap:40px;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
  .TelefonAbout{
    display:grid;
    margin-left:-80px;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    
  }
  .TAbout{
    display:grid;
    margin-left:3px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .TAboutPage{
    margin-left:170px;
  }
  .TAboutPage1{
    margin-left:70px;
  }
  .TelefonaboutPerson{
    display:grid;
    margin-left:2px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
  .TelefonaboutPerson2{
    font-size:12px;margin-right:100px;
  }
  .TelefonOurserviceItem{
    justify-content: center;
    items-align: center;margin-left:5px;
    margin-right: -40px;
  }
  .Telefon{
    width: 100%;z-index:-1; ;}
      
    .TContact{
      display:grid;
      fonts-size:14px;margin-top:10px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-template-rowns:[row1-start] 50% [row1-end] 50%;
    }
    .TContactItem{
      margin-left:-160px;
    }
    .TContactItem1{
      margin-left:-200px;
    }
    
    .TelefonReferenceItem{
      display:grid;
    margin-left:50px;
    grid-template-rowns: repeat(auto-fill, minmax(30px, 1fr));
    }
    .TelefonFooter{
      display:grid;
      width:100%;margin-left:-0px;
    grid-template-rowns: repeat(auto-fill, minmax(10px, 1fr));
    }
    .TReferenceM{
      display:grid;
    margin-left:-60px;
    font-size:26px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    .TReferenceM1{
      display:grid;
    margin-left:-530px;
    grid-template-rowns: repeat(auto-fill, minmax(10px, 1fr));
    }
    .TelefonScroll{
    z-index:99;
    display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;font-size:50px;
    }
    .Tloader{
      margin-right:50px;
      font-size:16px;
    }
    }
    .TelefonaboutItem2{
      margin-left:60px;
      width: 100%;
      margin-top:80px
    }
    .TelefonaboutItem1{
      width: 100%;
      margin-left:-150px;
      margin-right:170px;
    }
    .menu-icons{
    display:none;}
    .tmenu-icons{
      display:none;}